/**
 * @license
 * MyFonts Webfont Build ID 4121460, 2021-07-05T16:48:17-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: NHaasGroteskDSPro-45Lt by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-haas-grotesk/pro-display-45-light/
 *
 * Webfont: NHaasGroteskDSPro by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-haas-grotesk/pro-display-55-roman/
 *
 * Webfont: NHaasGroteskDSPro-56It by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-haas-grotesk/pro-display-56-italic/
 *
 * Webfont: NHaasGroteskDSPro-65Md by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/neue-haas-grotesk/pro-display-65-medium/
 *
 *
 * Webfonts copyright: Copyright &amp;#x00A9; 2013 Monotype Imaging Inc. All rights reserved.
 *
 * © 2021 MyFonts Inc
*/
/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3ee374");

/* DISPLAY VERSIONS */
/* regular weight */

@font-face {
  font-family: "NHaasGrotesk Display";
  font-weight: 300;
  font-style: normal;
  /* font-display: optional; */
  src: url("/fonts/NHaasGroteskDSPro-45Lt.woff") format("woff");
}
@font-face {
  font-family: "NHaasGrotesk Display";
  font-weight: 300;
  font-style: italic;
  /* font-display: optional; */
  src: url("/fonts/NHaasGroteskDSPro-46LtIt.woff") format("woff");
}
@font-face {
  font-family: "NHaasGrotesk Display";
  font-weight: bold;
  font-style: normal;
  /* font-display: optional; */
  src: url("/fonts/NHaasGroteskDSPro-65Md.woff") format("woff");
}
@font-face {
  font-family: "NHaasGrotesk Display";
  font-weight: bold;
  font-style: italic;
  /* font-display: optional; */
  src: url("/fonts/NHaasGroteskDSPro-66MdIt.woff") format("woff");
}
@font-face {
  /* TODO: change back to "Neue Haas Grotesk Display" to potentially use system fonts. done this way for now to check loading */
  font-family: "NHaasGrotesk Display";
  font-weight: normal;
  font-style: normal;
  /* font-display: optional; */
  src: url("/fonts/NHaasGroteskDSPro-55Rg.woff") format("woff");
}
@font-face {
  font-family: "NHaasGrotesk Display";
  font-weight: normal;
  font-style: italic;
  /* font-display: optional; */
  src: url("/fonts/NHaasGroteskDSPro-56It.woff") format("woff");
}

/* TEXT VERSIONS */
/* regular weight */
@font-face {
  font-family: "NHaasGrotesk Text";
  font-weight: normal;
  font-style: normal;
  /* font-display: optional; */
  src: url("/fonts/NHaasGroteskTXPro-55Rg.woff") format("woff");
}
@font-face {
  font-family: "NHaasGrotesk Text";
  font-weight: normal;
  font-style: italic;
  /* font-display: optional; */
  src: url("/fonts/NHaasGroteskTXPro-56It.woff") format("woff");
}

/* bold weight */
@font-face {
  font-family: "NHaasGrotesk Text";
  font-weight: bold;
  font-style: normal;
  /* font-display: optional; */
  src: url("/fonts/NHaasGroteskTXPro-65Md.woff") format("woff");
}
@font-face {
  font-family: "NHaasGrotesk Text";
  font-weight: bold;
  font-style: italic;
  /* font-display: optional; */
  src: url("/fonts/NHaasGroteskTXPro66-MdIt.woff") format("woff");
}

/* NB no 'lighter' versions of the text font */
